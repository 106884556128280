//import { cdnURL } from '../env'
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var AppConfig = /** @class */ (function () {
    function AppConfig() {
        this.config = null;
        this._isDevelopment = false;
        // Copied from shared components because we need to load the config before we load
        // the shared remote modules. Should probably figure out a different way.
        this.buildURL = function (part1, part2) {
            var newURL = part1 !== null && part1 !== void 0 ? part1 : '';
            if (newURL && newURL.slice(-1) !== '/' && (part2 === null || part2 === void 0 ? void 0 : part2.length) && part2[0] !== '/') {
                newURL += '/';
            }
            var newPart2 = part2 !== null && part2 !== void 0 ? part2 : '';
            if (newPart2[0] === '/') {
                newPart2 = newPart2.slice(1);
            }
            return newURL + newPart2;
        };
    }
    AppConfig.prototype.loadConfig = function (isDevelopment, filePath) {
        var _this = this;
        if (isDevelopment === void 0) { isDevelopment = false; }
        if (filePath === void 0) { filePath = '/config.json'; }
        this._isDevelopment = isDevelopment;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var response, _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 8, , 9]);
                        if (!this.isLoaded) return [3 /*break*/, 1];
                        resolve(true);
                        return [3 /*break*/, 7];
                    case 1:
                        if (!isDevelopment) return [3 /*break*/, 6];
                        return [4 /*yield*/, fetch(filePath, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                }
                            })];
                    case 2:
                        response = _b.sent();
                        if (!(response.status === 200)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, response.json()];
                    case 3:
                        _a.config = _b.sent();
                        resolve(true);
                        return [3 /*break*/, 5];
                    case 4:
                        if (response.status === 404) {
                            resolve(true);
                        }
                        else {
                            console.error('Could not fetch config file; response status: ' + response.status);
                            reject();
                        }
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        resolve(true);
                        _b.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        err_1 = _b.sent();
                        console.error('Could not fetch config file: ' + err_1.message);
                        reject();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    AppConfig.prototype.get = function (name) {
        if (!this.config) {
            console.error('The loadConfig method must be called before getting app config setting.');
            return null;
        }
        return this.config ? this.config[name] : null;
    };
    Object.defineProperty(AppConfig.prototype, "apiRoot", {
        get: function () {
            var _a, _b, _c, _d;
            return (_d = (this.isDevelopment && ((_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.apiRoot) === null || _b === void 0 ? void 0 : _b.length) ? (_c = this.config) === null || _c === void 0 ? void 0 : _c.apiRoot : window === null || window === void 0 ? void 0 : window.location.origin)) !== null && _d !== void 0 ? _d : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppConfig.prototype, "isLoaded", {
        get: function () {
            return this.config != null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppConfig.prototype, "isDevelopment", {
        get: function () {
            return this._isDevelopment;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppConfig.prototype, "cdnURL", {
        get: function () {
            var _a;
            return (_a = document === null || document === void 0 ? void 0 : document.scCDNURL) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppConfig.prototype, "compareProposalPage", {
        get: function () {
            var _a, _b;
            return (_b = (this.isDevelopment ? (_a = this.config) === null || _a === void 0 ? void 0 : _a.compareProposalPage : this.buildURL(this.cdnURL, '/compare/index.html'))) !== null && _b !== void 0 ? _b : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppConfig.prototype, "remoteSharedEntry", {
        // For now, in local development mode the remoteSharedEntry value will come from the configuration file.  Otherwise,
        // it comes in as a property on the Javascript document object from Sitecore.
        get: function () {
            var _a, _b, _c, _d;
            var setting = '';
            if ((_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.remoteSharedEntry) === null || _b === void 0 ? void 0 : _b.length) {
                setting = this.config.remoteSharedEntry;
            }
            else if (this.cdnURL.length) {
                setting = this.buildURL(this.cdnURL, '/shared/remoteEntryShared.js');
            }
            else {
                setting = '/shared/remoteEntryShared.js';
            }
            if (!(setting === null || setting === void 0 ? void 0 : setting.length)) {
                console.error('Could not find remote entry point for shared components.');
                console.info('configuration shared remote value: ' + ((_d = (_c = this.config) === null || _c === void 0 ? void 0 : _c.remoteSharedEntry) !== null && _d !== void 0 ? _d : ''));
                console.info('Sitecore CDN URL: ' + this.cdnURL);
            }
            return setting;
        },
        enumerable: false,
        configurable: true
    });
    return AppConfig;
}());
export default new AppConfig();

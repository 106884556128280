import { resolveRemote } from './utils/remotes';
// The 'resolveSharedRemote' method is assigned to the window object in order to be used by webpack module federation
// in the 'webpack.common.js' when initializing the remote
window.resolveRemote = resolveRemote;
// Our Sitecore code may try to load the bundle twice but that can cause problems so
// we prevent it from loading this code more than once with a flag on the window object.
if (!window.isCatalogLoaded) {
    import('./bootstrap');
    window.isCatalogLoaded = true;
}

import appConfig from '@/utils/config'
//import { cdnURL } from '@/env'

export const resolveRemote = async (remoteName, remoteConfigName, resolve) => {
    appConfig.loadConfig(process.env.NODE_ENV === 'development', '/config.json')
        .then(async () => {
            if (!appConfig[remoteConfigName]?.length) {
                console.log(remoteConfigName + ' URL is not available.')
            }
            else if (appConfig[remoteConfigName].indexOf('cdn-') > 0 || appConfig[remoteConfigName].indexOf('localhost') > 0) {
                const script = document.createElement('script')
                script.src = appConfig[remoteConfigName]
                script.onload = () => {           
                    const proxy = {
                        get: (request) => window[remoteName].get(request),
                        init: (arg) => {
                            try {
                                return window[remoteName].init(arg)
                            } catch(e) {
                                console.log(remoteName + ' remote container already initialized')
                            }
                        }
                    }
                    resolve(proxy)
                }
                document.head.appendChild(script)
            } else {
                console.warn('Could not load remote script for ' + appConfig[remoteConfigName])
                resolve()
            }
        })
}